import { useEffect, useMemo, useState } from 'react';
import { queries_kn_port_alerts, size_page } from '../../utils/constants';
import styles from './kn-port-alerts.module.scss';
import classNames from 'classnames';
import { Column, GroupDataWithTypesProps, KNPortAlertsInterface, KNPorts } from '../../utils/interfaces';
import { getUniques, groupData, queryV1 } from '../../services/python-services';
import { Table } from '../table/table';
import { addDayToDateString, arraysEqual, convertKeyToValue, formatterSingleStringToDate, formatterStringToDate, getArrayLikeMultipleQueries, getPropertiesWithDecodeFunction, handleCloseModalById, handleOpenModalById, reduceColumnsFunction, reduceColumnTypesFunction, shouldBeNull } from '../../utils/functions';
import { DateRangePicker } from '../date-range-picker/date-range-picker';
import { GenericModal } from '../generic-modal/generic-modal';
import { Button } from '../button/button';
import { CalendarDaysIcon, GlobeAmericasIcon } from '@heroicons/react/24/outline';
import { MultiSelectDropdown } from '../multi-select-dropdown/multi-select-dropdown';
import { FilterCard } from '../filter-card/filter-card';
import { FilterButton } from '../filter-button/filter-button';

export interface KNPortAlertsProps {
    className?: string;
}

export const KNPortAlerts = ({ className }: KNPortAlertsProps) => {
    const [loadingMainData, setLoadingMainData] = useState(true);
    const [lastRefresh, setLastRefresh] = useState('');

    const [closedFilters, setClosedFilters] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const [downLoader, setDownLoader] = useState<boolean>(false);
    const nameOfmodal = "edit-dateRangePicker";

    const initialUniquesValues = {
        page: 1,
        size: size_page,
        date_log: null,
        sort_by: null,
        name: null,
        port_status: null,
        code: null,
        searchKNPortsAlerts: null,
    };
    const [uniques, setUniques] = useState<KNPortAlertsInterface>({
        name: null,
        port_status: null,
        port_code: null,
    });
    const filtersString = [
        'page',
        'size',
        'name',
        'port_status',
        'port_code'
    ];

    const [queries, setQueries] = useState<KNPorts>(initialUniquesValues);

    const [customFilters, setCustomFilters] = useState<any>([]);
    const [searchText, setSearchText] = useState('');
    const [applySearch, setApplySearch] = useState(true);
    const [executeApply, setExecuteApply] = useState(true);

    const initialTableState: any[] = [];
    const [mainTableData, setMainTableData] = useState(initialTableState);
    const [totalDatas, setTotalDatas] = useState(0);

    //Pagination
    const [page, setPage] = useState(1);
    const [isLoadingDataScroll, setIsLoadingDataScroll] = useState(false);
    const [moreItems, setMoreItems] = useState(true);
    //soring
    const [sortBy, setSortBy] = useState('');

    const initialDateRangeState = { startDate: '', endDate: '' };
    const [dateRange, setDateRange] = useState<any>(initialDateRangeState)
    const [isReset, setIsReset] = useState(false);


    const applyFilters = (filters: any, type: string = '', apply: boolean = false) => {
        if (type === 'reset') {
            resetFilters();
        } else {
            //clearValues();
            setPage(1);
            const updatedQueries = { ...filters, page: 1, size: size_page };
            setQueries(updatedQueries);
            setUniques(updatedQueries);
        }
        setExecuteApply(apply);
        setApplySearch(apply);
    };

    const getNewQueyWithFilter = (query_C: any, isTotal = false, isExport = false) => {
        let query: any = { ...query_C };
        let data = [...query.data];
        if (!isTotal) {
            let sort = {};
            if (sortBy === '') {
                sort = { by: 'modified', descending: true };
                data[0] = { ...data[0], sort: sort };
            }
            query = { ...query, data: data };
        }
        if ((applySearch || isExport) && dateRange.startDate !== '' && dateRange.endDate !== '') {
            // Spread the array to create a copy
            let filters = `( modified >=  Date '${!isExport ? addDayToDateString(dateRange.startDate, 0) : addDayToDateString(dateRange.startDate, 0)
                }' and modified <=  Date '${!isExport ? addDayToDateString(dateRange.endDate, 0) : addDayToDateString(dateRange.endDate, 0)
                }')`;

            if (isTotal) {
                const select = [...query.data[0].select];
                let filters = `(modified >=  Date '${addDayToDateString(dateRange.startDate, 0)}' and modified <=  Date '${addDayToDateString(dateRange.endDate, 0)}')`;

                data[0] = { filter: filters, select: [...select] };
            } else {
                data[0] = { ...data[0], filter: filters };
            }

            query = { ...query, data: data };
            if (!isTotal) setPage(1);
        }
        return query;
    };
    const getMainData = async () => {
        let newQueiries = {
            ...queries,
            page: 1,
            size: size_page,
            // sort_by: sortBy !== '' ? sortBy : 'modified,desc',
        };
        let query: any = { ...queries_kn_port_alerts.mainTable.query }
        query = getNewQueyWithFilter(query);

        const response = await queryV1(
            queries_kn_port_alerts.mainTable.table,
            queries_kn_port_alerts.mainTable.groupsBy,
            query,
            newQueiries,
            'athena'
        );
        if (lastRefresh === "") {
            const last_refresh = await queryV1(
                queries_kn_port_alerts.lastRefresh.table,
                queries_kn_port_alerts.lastRefresh.groupsBy,
                queries_kn_port_alerts.lastRefresh.query,
                {},
                'athena'
            );
            if (last_refresh && last_refresh.data && last_refresh.data.length > 0) {
                const last_refresh_data = last_refresh.data[0];
                const date: any = last_refresh_data.dt.replace('T', '-').replaceAll(':', '-').split('-');
                setLastRefresh(`${date[0]}-${date[1]}-${date[2]} ${date[3]}:${date[4]}`);
            }
        }
        if (response && response.data) {
            const newData = response.data?.map((row: any, index: number) => {
                return { ...row, index: index };
            });
            setMainTableData(newData);
        }
        else {
            setMainTableData([]);
        }
    }
    const getTotalDatas = async () => {
        let filter_queries = { ...queries };
        filter_queries.sort_by = '';
        delete filter_queries.page;
        delete filter_queries.size;

        let query: any = { ...queries_kn_port_alerts.totalMainTable.query }
        query = getNewQueyWithFilter(query, true);
        const response = await queryV1(
            queries_kn_port_alerts.totalMainTable.table,
            queries_kn_port_alerts.totalMainTable.groupsBy,
            query,
            filter_queries,
            'athena'
        );
        if (response) {
            setTotalDatas(response?.data[0].totals);
            if (parseInt(response?.data[0].totals) <= mainTableData.length) {
                setMoreItems(false);
            } else {
                setMoreItems(true);
            }
        }
        setLoadingMainData(false);
    };


    useEffect(() => {
        if (applySearch) {
            getTotalDatas();
            getMainData();
            setApplySearch(false);
        }
    }, [
        applySearch]);


    const fetchData = async (isScroll: boolean = false) => {
        // avoid multiple call to the fetchData
        // console.log("fetchData", isScroll, loadingMainData, applySearch, page)
        if (!isReset) {
            if (loadingMainData) {
                return;
            }
            const updatedQueries = {
                ...queries,
                page: page + 1,
                size: size_page,
                sort_by: sortBy !== '' ? sortBy : 'modified,desc',
            };
            setQueries(updatedQueries);
            setIsLoadingDataScroll(isScroll);
            setLoadingMainData(true); // Establecer isLoadingData a true antes de la solicitud

            let query: any = { ...queries_kn_port_alerts.mainTable.query }

            // const sort = sortBy !== '' ? sortBy : 'modified,desc';
            // data[0] = { ...data[0], sort: sort };
            if (dateRange.startDate !== "" && dateRange.endDate !== "") {
                let data = [...query.data]; // Spread the array to create a copy
                let filters = `( modified >=  Date '${addDayToDateString(dateRange.startDate, 0)}' and modified <=  Date '${addDayToDateString(dateRange.endDate, 0)}')`;
                data[0] = { ...data[0], filter: filters };
                query = { ...query, data: data };
                if (applySearch)
                    setPage(1);
            }
            try {
                // make and run request
                const response = await queryV1(
                    queries_kn_port_alerts.mainTable.table,
                    queries_kn_port_alerts.mainTable.groupsBy,
                    query,
                    updatedQueries,
                    'athena'
                );
                // update data and state variables
                const newDataToAdd = response.data?.map((row: any, index: number) => {
                    return { ...row, index: index + page * size_page };
                });
                const newData: any[] = [...mainTableData, ...newDataToAdd];
                setMainTableData(isScroll ? newData : response.data);
                if (newData.length >= totalDatas) {
                    setMoreItems(false);
                } else {
                    setPage((prev) => prev + 1);
                }
                setExecuteApply(false);
                setApplySearch(false);

                // Reset loading states
                setLoadingMainData(false);
                setIsLoadingDataScroll(false);
            } catch (error) {
                // Reset loading states
                setIsLoadingDataScroll(false);
                setLoadingMainData(false);
            }
        }
    };
    const updateData = (newData: any) => {
        setMainTableData([]);
        setMainTableData(newData);
    };
    const resetFilters = (nameComponent: string = 'filter') => {
        //clearValues();
        setIsReset(true);
        if (nameComponent === 'table') {
            // setClosedFilters(true);
        }
        const updatedQueries = {
            page: 1,
            size: size_page,
            date_log: null,
            sort_by: null,
        };
        setSortBy("");
        setSearchText("");
        setPage(1);
        setQueries(updatedQueries);
        setApplySearch(true);
        setLoadingMainData(true);
        setExecuteApply(true);
        setDateRange(initialDateRangeState)
    };
    const mainColumns: Column[] = [
        { id: 'code', name: 'Code', prop: 'code', open: false, show: true, style: { maxWidth: '100px' } },
        { id: 'name', name: 'Name', prop: 'name', open: false, show: true, style: { maxWidth: '180px' } },
        {
            id: 'isseaport', name: 'Is Seaport', prop: 'isseaport', open: false, show: true, style: { maxWidth: '100px' },
            formatting: (v: any) => {
                return (
                    <div>
                        {v === "true" ? 'Yes' : 'No'}
                    </div>
                );
            },
        },
        // { id: 'latitude', name: 'Latitude', prop: 'latitude', open: false, show: true, style: { maxWidth: '100px' } },
        // { id: 'longitude', name: 'Longitude', prop: 'longitude', open: false, show: true, style: { maxWidth: '100px' } },
        { id: 'status', name: 'Status', prop: 'status', open: false, show: true, style: { maxWidth: '150px' } },
        {
            id: 'modified', name: 'Modified', prop: 'modified', open: false, show: true, style: { maxWidth: '160px' },
            cellType: 'pl.Date',
            formatting: (v: any) => {
                return (
                    <div>
                        {v !== null ? formatterSingleStringToDate(v) : '-'}
                    </div>
                );
            },
        },
        {
            id: 'comment', name: 'Comment', prop: 'comment', open: false, show: true, style: { maxWidth: '700px' },
            formatting: (v: any) => {
                return (
                    <div className={"truncate " + styles.tooltip} >
                        {v}
                        <span
                            className={styles.tooltipText} >
                            {v}
                        </span>

                    </div>
                );
            },
        },
        {
            id: 'latitude',
            name: 'Latitude',
            prop: 'latitude',
            open: false,
            show: true,
            cellType: 'pl.Float64',
            style: { maxWidth: '100px' },
        },
        {
            id: 'longitude',
            name: 'Longitude',
            prop: 'longitude',
            open: false,
            show: true,
            cellType: 'pl.Float64',
            style: { maxWidth: '100px' },
        },
        {
            id: 'alert_title', name: 'Alert Title', prop: 'alert_title', open: false, show: true, style: { maxWidth: '200px' },
            formatting: (v: any) => {
                return (
                    <div className={"truncate " + styles.tooltip} >
                        {v}
                        <span
                            className={styles.tooltipText} >
                            {v}
                        </span>

                    </div>
                );
            },
        },
        {
            id: 'alert_description', name: 'Alert Description', prop: 'alert_description', open: false, show: true, style: { maxWidth: '500px' },
            formatting: (v: any) => {
                return (
                    <div className={"truncate " + styles.tooltip} >
                        {v}
                        <span
                            className={styles.tooltipText} >
                            {v}
                        </span>

                    </div>
                );
            },
        },
        {
            id: 'alert_source', name: 'Alert Source', prop: 'alert_source', open: false, show: true, style: { minWidth: '150px' },
            formatting: (v: any) => {
                return (
                    <div className={"truncate " + styles.tooltip} >
                        {v}
                        <span
                            className={styles.tooltipText} >
                            {v}
                        </span>

                    </div>
                );
            },
        },
        {
            id: 'alert_from_date', name: 'Alert From Date', prop: 'alert_from_date', open: false, show: true, style: { maxWidth: '160px' },
            cellType: 'pl.Date',
            formatting: (v: any) => {
                return (
                    <div>
                        {v !== null ? formatterStringToDate(v) : '-'}
                    </div>
                );
            },
        },
        {
            id: 'alert_to_date', name: 'Alert To Date', prop: 'alert_to_date', open: false, show: true, style: { maxWidth: '160px' },
            cellType: 'pl.Date',
            formatting: (v: any) => {
                return (
                    <div>
                        {v !== null ? formatterStringToDate(v) : '-'}
                    </div>
                );
            },
        }
    ];
    const clearValues = () => {
        setMainTableData([]);
        setTotalDatas(0);
        setPage(1);
        setSearchText('');
    };

    const searchData = (type: any) => {
        setApplySearch(true);
        setLoadingMainData(true);
        type = type.replace(/\s+/g, ' ').trim();
        setSearchText(type);
        if (type === '') {
            clearValues();
            setQueries({ ...queries, searchKNPortsAlerts: null });
        } else if (type.length > 2) {
            clearValues();
            setQueries({ ...queries, searchKNPortsAlerts: type });
        }
    };
    const sortData = (type: any) => {
        setLoadingMainData(true);
        setApplySearch(true);
        setPage(1);
        if (type.name === 'Descending') {
            setSortBy(`${type.prop},desc`);
            setQueries({
                ...queries,
                page: 1,
                size: size_page * 1,
                sort_by: `${type.prop},desc`,
            });
        } else {
            setSortBy(`${type.prop}`);
            setQueries({ ...queries, page: 1, size: size_page * 1, sort_by: `${type.prop}` });
        }
        //setPage(1);
    };
    const handleDateRangeSave = (range: { startDate: string; endDate: string }) => {
        let dates = { ...range };
        dates.startDate = addDayToDateString(dates.startDate, 2)
        dates.endDate = addDayToDateString(dates.endDate, 2)
        setDateRange(dates);
        setLoadingMainData(true);
        setApplySearch(true);
        handleCloseModal()
    };

    const handleOpenModal = () => {
        handleOpenModalById(setOpenModal, nameOfmodal)
    };
    const handleCloseModal = () => {
        handleCloseModalById(setOpenModal, nameOfmodal)
    };
    const getDateRangeComponent = () => {
        return (<><DateRangePicker onSave={handleDateRangeSave} reset={isReset} setIsReset={setIsReset} />
        </>
        )
    }
    const exportExcel = async (columns: any) => {
        setDownLoader(true);
        setApplySearch(true);
        const col = reduceColumnsFunction(columns);
        const column_types = reduceColumnTypesFunction(columns);
        let body: any = { ...queries_kn_port_alerts.mainTable.query };
        body = getNewQueyWithFilter(body, false, true);
        delete body.data[0].offset;
        delete body.data[0].limit;
        delete body.data[0].searchknportsalerts;

        const payloadGroupData: GroupDataWithTypesProps = {
            queries: [
                {
                    service: 'queryV1',
                    kwargs: {
                        groups: queries_kn_port_alerts.mainTable.groupsBy,
                        name: queries_kn_port_alerts.mainTable.table,
                    },
                    body: body,
                },
            ],
            export: {
                columns: col,
                column_types: column_types,
                format: 'xlsx',
            },
        };
        const export_query = { ...queries };
        delete export_query.sort_by;
        delete export_query.page;
        delete export_query.size;
        const res = await groupData(payloadGroupData, export_query, 'athena');
        window.open(res['url']);
        setDownLoader(false);
    };
    const closeFilter = (isClosed: boolean) => {
        setClosedFilters(isClosed);
    };
    // const handleSelectChange = (event: any) => {
    //     // setSelectedOption(event);
    //     // setColumnFilters(() => {
    //     //     if (event.includes('All')) {
    //     //         return [];
    //     //     } else {
    //     //         return [{ id: 'country', value: event }];
    //     //     }
    //     // });
    // };
    const headerFilterCalendar = () => {
        return (<div className={styles.additionalFilter}>
            <div>
                <div>
                    <div >
                        <div className={styles.firstNameInput}>
                            <label className={styles.labelFilter}>Modified: </label>
                            <Button
                                key={'bt-reset'}
                                click={handleOpenModal}
                                styleProp="inline-block rounded border px-2 py-1 text-sm font-medium focus:outline-none focus:ring border-light-gray bg-white text-dark-gray flex gap-1 items-center"
                            >
                                <CalendarDaysIcon className="h-4 w-4" /> Filter by Date

                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>)
    };
    const MemoizedTable = useMemo(() => {
        return (
            <div className={styles['mainTable']}>
                <Table
                    isSearch={true}
                    searchFunction={searchData}
                    searchText={searchText}
                    sortFunction={sortData}
                    columnsButton={true}
                    resetButton={true}
                    columns={mainColumns}
                    data={mainTableData}
                    count={totalDatas}
                    exportLoader={downLoader}
                    fetchData={fetchData}
                    title=""
                    // subtitle={`Last Update: ${lastRefresh}`}
                    exportData={exportExcel}
                    haveMoreItems={moreItems}
                    updateData={updateData}
                    isLoadingData={loadingMainData}
                    isLoadingDataScroll={isLoadingDataScroll}
                    allColumnsStyle="w-full"
                    activeCompactMode={true}
                    onReset={resetFilters}
                    hasAdditionalHeader={true}
                    additionalHeader={headerFilterCalendar}
                />
            </div>
        );
    }, [
        searchData,
        searchText,
        sortData,
        mainColumns,
        mainTableData,
        totalDatas,
        // downLoader,
        // fetchData,
        // exportExcel,
        moreItems,
        updateData,
        loadingMainData,
        // isLoadingDataScroll,
        resetFilters,
        clearValues,
    ]);


    return <div className={classNames(styles.root, className) + ' flex h-[98vh] w-[100%] '}>
        {!closedFilters && (
            <FilterCard
                close={closeFilter}
                applyFilters={applyFilters}
                columns={mainColumns}
                setUniques={setUniques}
                filters={filtersString}
                file={'glp-knportstatus'}
                uniques={uniques}
                by="athena"
                onReset={clearValues}
                setExecuteApply={setExecuteApply}
                setApplySearch={setApplySearch}
                setCustomFilters={setCustomFilters}
                customFilters={customFilters}
                isColapsed={true}
            />
        )}
        <div className={closedFilters ? 'w-[100%]' : 'w-[80%]'}>
            {!loadingMainData && (
                <div className={styles.root}>
                    <div className={styles.filterRange}>
                        <GenericModal
                            className="dateRangePicker"
                            closeDialog={handleCloseModal}
                            haveComponent={true}
                            getComponent={getDateRangeComponent()}
                            name={'edit-dateRangePicker'}
                            title={''} />

                    </div>

                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            maxWidth: '100%',
                            // margin: '7px',
                        }}
                        // className={(closedFilters ? 'w-[100%]' : 'w-[80%]')}
                    >

                        <div className={"titlePage"} style={{ border: 'none' }}>

                            <div className={styles.title}>
                                K&N Port Alerts
                                <span className={styles.lastRefresh}>Last Update: {lastRefresh}</span>
                            </div>
                            <div>
                                {closedFilters && (
                                    <div className={"filterButton"} style={{ marginTop: 17 }}>
                                        <FilterButton
                                            className="hidden"
                                            close={closeFilter}
                                            top={154}
                                            regularFormat={true}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>


                        <div className="contentPage">
                            <div>{MemoizedTable}</div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    </div>;
};
