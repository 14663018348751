import { ArrowDownCircleIcon } from '@heroicons/react/24/outline';
import styles from './alert-history-box.module.scss';

export interface AlertHistoryBoxProps {
    alertHistory?: any;
}

export type TransformedData = {
    dt: string;
    group: {
        comment: string;
        status: string;
        latitude: string;
        longitude: string;
        hour: string;
    }[];
};

export const AlertHistoryBox = ({ alertHistory }: AlertHistoryBoxProps) => {
    const transformed: TransformedData[] = alertHistory.reduce(
        (acc: TransformedData[], item: any) => {
            const [date, time] = item.dt.split(/-(?=\d{2}-\d{2}$)/);
            const formattedTime = time.replace('-', ':');

            let group = acc.find((entry) => entry.dt === date);
            if (!group && item.comment) {
                group = { dt: date, group: [] };
                acc.push(group);
            }

            {
                item.comment &&
                    group?.group.push({
                        comment: item.comment,
                        status: item.status,
                        latitude: item.latitude,
                        longitude: item.longitude,
                        hour: formattedTime,
                    });
            }

            return acc;
        },
        []
    );

    transformed.sort((a, b) => {
        return new Date(b.dt).getTime() - new Date(a.dt).getTime();
    });

    transformed.forEach((entry) => {
        entry.group.sort((a, b) => b.hour.localeCompare(a.hour));
    });
    const getStatusIcon = (status: string): string => {
        return status === 'HEAVILY_REDUCED'
            ? 'heavily.png'
            : status === 'SLIGHTLY_REDUCED'
            ? 'slightly.png'
            : status === 'CLOSED'
            ? 'closed.png'
            : status === 'BAU'
            ? 'main.png'
            : 'default.png';
    };

    return (
        <div className={styles.masterBox}>
            {transformed.map((x: any) => (
                <div className={styles.boxDiv}>
                    <div className={styles.date}>{x.dt}</div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '15px',
                            paddingLeft: '20px',
                        }}
                    >
                        {x.group.map((y: any) => (
                            <div className={styles.infoBox}>
                                <div>
                                    <img
                                        src={'/images/circle-' + getStatusIcon(y?.status)}
                                        width={20}
                                        height={20}
                                    />
                                </div>
                                <div className={styles.commentAndHour}>
                                    <div className={styles.comment}>{y.comment}</div>
                                    <div className={styles.hour}>{y.hour}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
};
