import InfiniteScroll from 'react-infinite-scroller';
import { TableRow } from '../table-row/table-row';
import { Column } from '../../utils/interfaces';
import { Loader } from '../loader/loader';
import { useEffect, useRef } from 'react';

export interface TableRowsProps {
    className?: string;
    data?: any;
    columns?: Column[];
    fetchData: Function;
    haveMore: boolean;
    scrollRef?: HTMLElement | null;
    selectId?: (id: number) => void | undefined; allColumnsStyle?: string;
    idsArray: number[] | undefined;
}

/**
 * This component was created using Codux's Default new component template.
 * To create custom component templates, see https://help.codux.com/kb/en/article/kb16522
 */
export const TableRows = ({
    data,
    columns,
    fetchData,
    haveMore,
    selectId,
    // scrollRef = null, 
    allColumnsStyle,
    idsArray,
}: TableRowsProps) => {
    // let scrollRef: HTMLElement | null = null;
    const scrollRef = useRef<HTMLDivElement | null>(null);
    const handleLoadMore = () => {
        // console.log("handleLoadMore")
        if (haveMore && data.length > 0) {
            fetchData(true);
        }
    };
    function computeFields(rows: any) {
        // select only columns which have computed function
        let computedColumns = columns?.filter((c: any) => c.computed);


        // for each computed column...
        computedColumns?.forEach((column: any) => {
            // compute each row...
            rows.map((row: any) => {
                row[column.key] = column.computed(row);
                return row;
            });
        });


    }
    // console.log("computeFields",haveMore)
    computeFields(data)
    return (<div
         style={{ 
        // height: '85vh' ,
        overflowY: 'auto',
        height:'80%'
        
        }}
         ref={scrollRef}
    >
        <InfiniteScroll
        style={{height:'73vh'}}
            loadMore={handleLoadMore}
            hasMore={haveMore}
            loader={<Loader title='Loading Table Rows' key={'loaderTable'} style={{marginLeft:'50px!important', justifyContent: 'start'
                , height:'50px'
            }} />}
            useWindow={false}
            getScrollParent={() => scrollRef.current}
            autoPlay={false}
        >
            {data?.map((d: any, index: number) => (
                <TableRow idsArray={idsArray} allColumnsStyle={allColumnsStyle} selectId={selectId} data={d} columns={columns} key={index} />
            ))}
        </InfiniteScroll>
    </div>
    );
};
