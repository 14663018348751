import { useContext, useEffect, useState, useMemo } from 'react';
import styles from './glp-ports.module.scss';
import classNames from 'classnames';
import { Alert, Spinner } from 'flowbite-react';
import {
    query_glp_general_alerts,
    glp_ports,
    query_glp_last_refresh,
    max_vessel_inf,
    queries_kn_general_alerts,
} from '../../utils/constants';
import { postActionLogs, queryV1, getUniques } from '../../services/python-services';
import { removeDuplicates, viewInArrayViews } from '../../utils/functions';
import { RMContext } from '../../context/RMContext';
import { Button } from '../button/button';
import { AxiosResponse } from 'axios';
import { PortMap } from '../port-map/port-map';
import { RadioButtonForm } from '../radio-button-form/radio-button-form';
export interface GlpPortsProps {
    className?: string;
    name?: string;
}
export interface GLPMapFilter {
    status: string | null;
    country_code: string | null;
    [key: string]: string | null;
}

export const GlpPorts = ({ className }: GlpPortsProps) => {
    const [lastRefresh, setLastRefresh] = useState('');
    const [refresh, setRefresh] = useState(false);

    const currentView = window.location.href.split('/').slice(-1)[0];
    const { sitemap } = useContext(RMContext).userData;

    async function getCountriesData() {
        const response = await getUniques('glp-ports', 'country_code', null, 'athena');
        console.log('response', response);
        setCountries(response);
    }

    useEffect(() => {
        getCountriesData();
    }, []);

    useEffect(() => {
        const viewD = viewInArrayViews(currentView, sitemap);
        if (viewD?.hasOwnProperty('id')) {
            postActionLogs(viewD.id);
        }
    }, [sitemap]);
    enum AlertType {
        Any,
        Port,
        General,
        Vessel,
    }

    const [portsOrAny, setPortsOrAny] = useState(true);
    const [countries, setCountries] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [applyFilter, setApplyFilter] = useState(false);
    const [touch, setTouch] = useState(false);
    const [portData, setPortData] = useState<any[]>([]);
    const [vesselData, setVesselData] = useState<any[]>([]);
    const [alertData, setAlertData] = useState<any[]>([]);
    const [generalAlerts, setGeneralAlerts] = useState<any[]>([]);
    const [alertTypes, setalertTypes] = useState<AlertType>();
    const initialFilters = {
        status: null,
        country_code: null,
    };
    const [filter, setFilter] = useState<GLPMapFilter>(initialFilters);
    const initialCoordinates = { lat: 15.0, lng: 15.0 };
    const [center, setCenter] = useState(initialCoordinates);
    const initialZoom = 3.0;
    const [zoom, setZoom] = useState<any>(initialZoom);
    const getMapData = async () => {
        setRefresh(true);
        let ports: Promise<any> | AxiosResponse = queryV1(
            glp_ports.table,
            glp_ports.groupsBy,
            glp_ports.query,
            filter,
            'athena'
        );
        let last_refresh: Promise<any> | AxiosResponse = queryV1(
            query_glp_last_refresh.table,
            query_glp_last_refresh.groupsBy,
            query_glp_last_refresh.query,
            {},
            'athena'
        );
        let general_alerts: Promise<any> | AxiosResponse | any[] = queryV1(
            query_glp_general_alerts.table,
            query_glp_general_alerts.groupsBy,
            query_glp_general_alerts.query,
            {},
            'athena'
        );
        let vessels_inf: Promise<any> | AxiosResponse | any[] = queryV1(
            max_vessel_inf.table,
            max_vessel_inf.groupsBy,
            max_vessel_inf.query,
            {},
            'athena'
        );

        // parallel calls
        ports = (await ports) as AxiosResponse;
        general_alerts = (await general_alerts) as AxiosResponse;
        last_refresh = (await last_refresh) as AxiosResponse;
        vessels_inf = (await vessels_inf) as AxiosResponse;
        general_alerts = removeDuplicates(general_alerts.data);
        setPortData(ports.data);
        setAlertData(general_alerts);
        setVesselData(vessels_inf.data);
        let date: any = last_refresh.data[0].dt.replace('T', '-').replaceAll(':', '-').split('-');
        setLastRefresh(`${date[0]}-${date[1]}-${date[2]} ${date[3]}:${date[4]}`);

        if (ports && ports.data?.length > 0) {
            if (filter.country_code !== null) {
                const coun = ports?.data?.filter(
                    (c: any) => c.country_code === filter.country_code
                );
                if (coun !== null && coun.length > 0) {
                    setCenter({
                        lat: parseFloat(coun[0].latitude),
                        lng: parseFloat(coun[0].longitude),
                    });
                    if (zoom !== 7.5) setZoom(7.5);
                } else {
                    setCenter(initialCoordinates);
                    if (zoom !== initialZoom) setZoom(initialZoom);
                }
            } else {
                setCenter(initialCoordinates);
            }
        } else {
            setCenter(initialCoordinates);
            if (zoom !== initialZoom) setZoom(undefined);
        }

        setDataLoaded(true);
        setRefresh(false);
        if (applyFilter) {
            setApplyFilter(false);
        }
    };

    useEffect(() => {
        if (!dataLoaded || applyFilter) {
            getMapData();
        }
    }, [dataLoaded, applyFilter]);

    const setNewFilter = (key: string, value: string) => {
        setTouch(false);
        setFilter({ ...filter, [key]: value });
        setApplyFilter(true);
    };
    const setNewFilterSeverity = (key: string, value: string) => {
        setTouch(false);
        setZoom(initialZoom);
        setFilter({ ...filter, [key]: value });
        setApplyFilter(true);
    };

    const changeAlertTypeDisplayed = (type: AlertType) => {
        setTouch(true);
        setalertTypes(type);
        setApplyFilter(true);
    };
    const resetFilters = () => {
        setZoom(initialZoom);
        setFilter(initialFilters);
        setalertTypes(AlertType.Any);
        setApplyFilter(true);
        setTouch(false);
        setCenter(initialCoordinates);
    };

    const generals = ['SLIGHTLY_REDUCED', 'HEAVILY_REDUCED'];

    const memoizedMap = useMemo(() => {
        console.log('alerttype', AlertType);
        return (
            <PortMap
                className="glpMap"
                title="Ports"
                data_chart={{
                    data: [],
                }}
                center_map={center}
                zoom={zoom}
                ports={
                    alertTypes === AlertType.Port ||
                    (alertTypes === AlertType.Any && portData.length > 0)
                        ? portData
                        : []
                }
                alerts={
                    (alertTypes === AlertType.General || alertTypes === AlertType.Any) &&
                    filter.status !== 'SLIGHTLY_REDUCED' &&
                    filter.status !== 'HEAVILY_REDUCED'
                        ? alertData
                        : []
                }
                vessels={
                    (alertTypes === AlertType.Vessel || alertTypes === AlertType.Any) &&
                    filter.status !== 'SLIGHTLY_REDUCED' &&
                    filter.status !== 'HEAVILY_REDUCED'
                        ? vesselData
                        : []
                }
                touch={touch}
            />
        );
    }, [center, zoom]);

    useEffect(() => {
        resetFilters();
    }, []);
    useEffect(() => {
        if (dataLoaded) {
            setCenter(initialCoordinates);
            setZoom(initialZoom);
        }
    }, [dataLoaded]);

    console.log('yessir', portsOrAny);

    return (
        <div className={classNames(styles.root, className) + ' flex h-[100%] w-screen '}>
            <div style={{ width: '100%' }}>
                {!dataLoaded ? (
                    <div className={styles.wrapperForSpinner}>
                        <Spinner className={styles.tittle} />
                    </div>
                ) : (
                    vesselData?.length > 0 &&
                    portData?.length >= 0 &&
                    alertData?.length > 0 && (
                        <div className={styles['chartMap']}>
                            <div className={styles['map-tools']}>
                                <div className={styles['filters']}>
                                    <div className={styles['filter']}>
                                        <br />
                                        <div className={styles['filter-btns']}>
                                            <Button
                                                click={() => resetFilters()}
                                                styleProp="inline-block rounded border px-2 py-1 text-sm font-medium focus:outline-none focus:ring border-light-gray bg-white text-dark-gray flex gap-1 items-center h-full"
                                                style={{ padding: '5px 12px' }}
                                            >
                                                RESET FILTERS
                                            </Button>
                                        </div>
                                    </div>
                                    <div className={styles['filter']}>
                                        By Country
                                        <div
                                            className={styles['filter-btns']}
                                            style={{ maxWidth: '200px', overflowX: 'scroll' }}
                                        >
                                            {countries.map((country: any, index: number) => (
                                                <Button
                                                    key={'btn-m-' + index}
                                                    click={() => {
                                                        const newFilter =
                                                            filter.country_code ===
                                                            country.country_code
                                                                ? null
                                                                : country.country_code;
                                                        setNewFilter('country_code', newFilter);
                                                    }}
                                                    styleProp="inline-block rounded border px-2 py-1 text-sm font-medium focus:outline-none focus:ring border-light-gray bg-white text-dark-gray flex gap-1 items-center h-full"
                                                    style={
                                                        filter.country_code === country.country_code
                                                            ? {
                                                                  backgroundColor: '#333333',
                                                                  color: 'white',
                                                                  padding: '5px 12px',
                                                              }
                                                            : {
                                                                  padding: '5px 12px',
                                                              }
                                                    }
                                                >
                                                    {country.country_code}
                                                </Button>
                                            ))}
                                        </div>
                                    </div>
                                    <div>
                                        {portsOrAny && (
                                            <div className={styles['filter']}>
                                                <p>By Severity</p>
                                                <div className={styles['filter-btns']}>
                                                    {generals.map((general: any, index: number) => (
                                                        <Button
                                                            key={'btn-m-' + index}
                                                            click={() => {
                                                                const newFilter =
                                                                    filter.status === general
                                                                        ? null
                                                                        : general;
                                                                setNewFilterSeverity(
                                                                    'status',
                                                                    newFilter
                                                                );
                                                            }}
                                                            styleProp="inline-block rounded border px-2 py-1 text-sm font-medium focus:outline-none focus:ring border-light-gray bg-white text-dark-gray flex gap-1 items-center h-full"
                                                            style={
                                                                filter.status === general
                                                                    ? {
                                                                          backgroundColor:
                                                                              '#333333',
                                                                          color: 'white',
                                                                          padding: '5px 12px',
                                                                      }
                                                                    : {
                                                                          padding: '5px 12px',
                                                                      }
                                                            }
                                                        >
                                                            {general.replace('_REDUCED', '')}
                                                        </Button>
                                                    ))}
                                                </div>
                                            </div>
                                        )}
                                        <div className={styles['filter']}>
                                            <RadioButtonForm
                                                props={{
                                                    options: AlertType,
                                                    titleRatio: 'By Type',
                                                    types: alertTypes,
                                                    setPortsOrAny: setPortsOrAny,
                                                }}
                                                onChange={changeAlertTypeDisplayed}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', gap: '30px' }}>
                                    {refresh ? ( //this is the spinner for processing whats going on
                                        <div className={styles.overlay}>
                                            <div className={styles.wrapperForSpinner}>
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 24 24"
                                                    fill="black"
                                                    className={`w-6 h-6 ${styles.spin}`}
                                                >
                                                    <path d="M4.755 10.059a7.5 7.5 0 0 1 12.548-3.364l1.903 1.903h-3.183a.75.75 0 1 0 0 1.5h4.992a.75.75 0 0 0 .75-.75V4.356a.75.75 0 0 0-1.5 0v3.18l-1.9-1.9A9 9 0 0 0 3.306 9.67a.75.75 0 1 0 1.45.388Zm15.408 3.352a.75.75 0 0 0-.919.53 7.5 7.5 0 0 1-12.548 3.364l-1.902-1.903h3.183a.75.75 0 0 0 0-1.5H2.984a.75.75 0 0 0-.75.75v4.992a.75.75 0 0 0 1.5 0v-3.18l1.9 1.9a9 9 0 0 0 15.059-4.035.75.75 0 0 0-.53-.918Z" />
                                                </svg>
                                            </div>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                    <div style={{ marginRight: '25px' }}>
                                        <div>Last refresh date:</div>
                                        {lastRefresh}
                                    </div>
                                </div>
                            </div>
                            {dataLoaded && memoizedMap}
                        </div>
                    )
                )}
            </div>
        </div>
    );
};
