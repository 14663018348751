import { useEffect, useState, useMemo, Children } from 'react';
import { Map } from '../map/map';
import { Marker, InfoWindow } from '@react-google-maps/api';
import styles from './port-map.module.scss';
import {
    max_container_inf,
    queries_kn_general_alerts,
    queries_kn_port_status,
} from '../../utils/constants';
import { queryV1 } from '../../services/python-services';
import { Link } from 'react-router-dom';
import { getDifferentContiners } from '../../utils/functions';
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { AlertHistoryBox } from './sub-components/alert-history-box/alert-history-box';
import { parse } from 'path';
import { GeneralHistoryBox } from './sub-components/general-history-box/general-history-box';
export interface MapProps {
    className?: string;
    title?: string;
    data_chart?: any;
    center_map?: any;
    zoom?: any;
    customTitle?: any;
    ports?: any;
    alerts?: any;
    vessels?: any;
    generalAlert?: any;
    containers?: any;
    lead_time?: any;
    touch?: boolean;
}

export interface ContainerMapVessels {
    vessel_id: string | null;
    [key: string]: string | null;
}

export const PortMap = ({
    className,
    title = '',
    data_chart,
    center_map = null,
    zoom = null,
    ports,
    alerts,
    vessels,
    lead_time,
    touch,
}: MapProps) => {
    const [portMarker, setPortMarker] = useState<Marker[]>([]);
    const [alertMarker, setAlertMarker] = useState<Marker[]>([]);
    const [vesselMarker, setVesselMarker] = useState<Marker[]>([]);
    const [pinMarker, setPinMarker] = useState<Marker[]>([]);
    const [showTooltip, setShowTooltip] = useState('');
    const [showQuickInf, setShowQuickInf] = useState('');
    const [containerInfo, setContainerInfo] = useState<any>([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isLoadedMarkets, setIsLoadedMarket] = useState(false);
    const [spinner, setSpinner] = useState('');
    const [alertHistory, setAlertHistory] = useState([]);
    const [generalHistory, setGeneralHistory] = useState([]);
    const regexDate = /\d{4}-\d{2}-\d{2}/;
    const onClickMarker = async (id: string, longitude?: any, latitude?: any) => {
        setIsOpen(false);
        setIsOpenGeneral(false);
        const vessel = vessels?.find((vessel: any) => vessel.ship_id === id);
        const port = ports?.find((port: any) => port.id === id);
        const general = alerts?.find(
            (alert: any) => alert.latitude === latitude && alert.longitude === longitude
        );
        if (vessel) {
            setSpinner(id);
            await getContainers(vessel.ship_id, 'vessel_id');
        } else if (port) {
            setSpinner(id);
            await getAlertHistory(port.port_code);
            await getContainers(port, 'port_code');
        } else if (general) {
            setSpinner(id);
            await getGeneralHistory(id, general.latitude, general.longitude);
        } else {
            setShowTooltip(id);
        }
    };
    const getContainers = async (value: any, kind: any) => {
        try {
            const queryParameters =
                kind == 'vessel_id'
                    ? { vessel_id: value }
                    : { currentportunlocode: value.port_code };

            const container_inf = await queryV1(
                max_container_inf(kind).table,
                max_container_inf(kind).groupsBy,
                max_container_inf(kind).query,
                queryParameters,
                'athena'
            );
            setContainerInfo(container_inf.data);
            setShowTooltip(kind == 'vessel_id' ? value : value.id);
            setSpinner('');
        } catch (error) {
            console.error('Error fetching container information:', error);
        }
    };

    const getAlertHistory = async (portCode: any) => {
        try {
            const alertHistory_inf = await queryV1(
                queries_kn_port_status.mainTable.table,
                queries_kn_port_status.mainTable.groupsBy,
                queries_kn_port_status.mainTable.query,
                { code: portCode },
                'athena'
            );
            setAlertHistory(alertHistory_inf.data);
            setSpinner('');
        } catch (error) {
            console.error('Error fetching container information:', error);
        }
    };

    const getGeneralHistory = async (id: any, latitude: any, longitude: any) => {
        try {
            const generalHistory_inf = await queryV1(
                queries_kn_general_alerts.mainTable.table,
                queries_kn_general_alerts.mainTable.groupsBy,
                queries_kn_general_alerts.mainTable.query,
                { latitude: `eval:${latitude}`, longitude: `eval:${longitude}` },
                'athena'
            );
            setGeneralHistory(generalHistory_inf.data);
            setSpinner('');
            setShowTooltip(id);
        } catch (error) {
            console.error('Error fetching container information:', error);
        }
    };
    const onCloseTooltip = () => {
        setShowTooltip('');
    };

    const getStatusIcon = (status: string, amount: number) => {
        if (amount > 0) {
            return status === 'HEAVILY_REDUCED'
                ? 'heavily-notify.png'
                : status === 'SLIGHTLY_REDUCED'
                ? 'slightly-notify.png'
                : status === 'BAU'
                ? 'closed-notify.png'
                : 'main-notify.png';
        } else {
            return status === 'HEAVILY_REDUCED'
                ? 'heavily.png'
                : status === 'SLIGHTLY_REDUCED'
                ? 'slightly.png'
                : status === 'BAU'
                ? 'closed.png'
                : 'main.png';
        }
    };
    const getStatusDescription = (status: string) => {
        return status === 'HEAVILY_REDUCED'
            ? 'Heavily Disrupted Operations'
            : status === 'SLIGHTLY_REDUCED'
            ? 'Slightly Disrupted Operations'
            : status === 'BAU'
            ? 'Closed'
            : 'Active';
    };

    const onMouseHover = async (id: string) => {
        setShowQuickInf(id);
    };
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenGeneral, setIsOpenGeneral] = useState(false);

    const openAlertHistory = () => {
        setIsOpen((x: any) => !x);
    };

    const openGeneralHistory = () => {
        setIsOpenGeneral((x: any) => !x);
    };

    useEffect(() => {
        if (isLoaded) {
            const portMarkerMap = ports?.map((port: any, index: number) => {
                return (
                    <Marker
                        key={'markPortInMap' + index}
                        position={{
                            lat: parseFloat(port.latitude),
                            lng: parseFloat(port.longitude),
                        }}
                        onClick={() => onClickMarker(port.id)}
                        icon={{
                            url:
                                '/images/circle-' +
                                getStatusIcon(port?.status || '', port?.amount || ''),
                            scaledSize: new google.maps.Size(18, 18),
                        }}
                        onMouseOver={() => onMouseHover(port.id)}
                        onMouseOut={() => setShowQuickInf('')}
                    >
                        {spinner === port.id && showTooltip !== port.id && (
                            <InfoWindow>
                                <div style={{ overflow: 'hidden' }}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        fill="#002b55"
                                        className={`w-6 h-6 ${styles.spin}`}
                                    >
                                        <path d="M4.755 10.059a7.5 7.5 0 0 1 12.548-3.364l1.903 1.903h-3.183a.75.75 0 1 0 0 1.5h4.992a.75.75 0 0 0 .75-.75V4.356a.75.75 0 0 0-1.5 0v3.18l-1.9-1.9A9 9 0 0 0 3.306 9.67a.75.75 0 1 0 1.45.388Zm15.408 3.352a.75.75 0 0 0-.919.53 7.5 7.5 0 0 1-12.548 3.364l-1.902-1.903h3.183a.75.75 0 0 0 0-1.5H2.984a.75.75 0 0 0-.75.75v4.992a.75.75 0 0 0 1.5 0v-3.18l1.9 1.9a9 9 0 0 0 15.059-4.035.75.75 0 0 0-.53-.918Z" />
                                    </svg>
                                </div>
                            </InfoWindow>
                        )}
                        {showQuickInf === port.id && showTooltip === '' && !spinner && (
                            <InfoWindow options={{ disableAutoPan: true }}>
                                <div style={{ alignItems: 'center' }}>
                                    <h5>Port</h5>
                                    <h5
                                        style={{
                                            fontWeight: '500',
                                            color: '#002b55',
                                            fontSize: '15px',
                                        }}
                                    >
                                        {port.name}
                                    </h5>
                                    <h5>Click for more inf</h5>
                                    {port.amount > 0 && (
                                        <h5 style={{ color: 'red' }}>
                                            {port.amount} container{port.amount > 1 && 's'}
                                        </h5>
                                    )}
                                </div>
                            </InfoWindow>
                        )}
                        {showTooltip === port.id && (
                            <InfoWindow onCloseClick={onCloseTooltip} options={{ maxWidth: 1200 }}>
                                <div className={styles.portsContainer}>
                                    <div className={styles.header}>
                                        <div>
                                            <h5 className={styles.portName}>{port.name}</h5>
                                            <h5 className={styles.portDescription}>{port.code}</h5>
                                        </div>

                                        <div className={styles.information}>
                                            <div>
                                                <h5 className={styles.portStatus}>Status</h5>
                                                <div
                                                    className={
                                                        'row ' + styles.portDescriptionStatus
                                                    }
                                                >
                                                    <h5 className={'col flex'}>
                                                        <img
                                                            className={styles.imgStatus}
                                                            alt="Status"
                                                            src={
                                                                '/images/circle-' +
                                                                getStatusIcon(port?.status || '', 0)
                                                            }
                                                        />
                                                        &nbsp; {getStatusDescription(port.status)}
                                                    </h5>
                                                </div>
                                            </div>
                                            {port.status !== null && (
                                                <>
                                                    <div>
                                                        <h5 className={styles.portStatus}>
                                                            Last Update
                                                        </h5>
                                                        <h5 className={styles.portDescription}>
                                                            {port.modified}
                                                        </h5>
                                                    </div>
                                                </>
                                            )}
                                            {port.status !== null && port.status == 'CLOSED' && (
                                                <>
                                                    <div className={styles.portDescription}>
                                                        <h5 className={styles.portStatus}>
                                                            Active since
                                                        </h5>
                                                        <h5>{port.alert_from_date}</h5>
                                                    </div>
                                                    <div className={styles.portDescription}>
                                                        <h5 className={styles.portStatus}>
                                                            Active to
                                                        </h5>
                                                        <h5>{port.alert_to_date}</h5>
                                                    </div>
                                                </>
                                            )}
                                            {port.status == null && (
                                                <>
                                                    <div className={styles.portDescription}>
                                                        <h5 className={styles.portStatus}>
                                                            Latitude
                                                        </h5>
                                                        <h5>{port.latitude}</h5>
                                                    </div>
                                                    <div className={styles.portDescription}>
                                                        <h5 className={styles.portStatus}>
                                                            Longitude
                                                        </h5>
                                                        <h5>{port.longitude}</h5>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>

                                    {port.status !== null && (
                                        <div>
                                            <div className={styles.portStatus}>
                                                <h5>Status Comment</h5>
                                            </div>
                                            <div className={styles.portDescription}>
                                                <h5>{port.comment}</h5>
                                            </div>
                                        </div>
                                    )}
                                    {containerInfo.length > 0 ? (
                                        <>
                                            <div className={styles.table_wrapper}>
                                                <table>
                                                    <thead>
                                                        <th>CONTAINER</th>
                                                        <th>CLIENT</th>
                                                        <th>SKU</th>
                                                        <th>QTY</th>
                                                        <th
                                                            style={{
                                                                borderLeft: '1px solid #002b55',
                                                                borderRight: '1px solid #002b55',
                                                            }}
                                                        >
                                                            ATA
                                                        </th>
                                                        <th>TRACK SHIPMENT</th>
                                                    </thead>
                                                    <tbody>
                                                        {containerInfo.map((c: any) => (
                                                            <tr>
                                                                <td>{c.cntr_truckno}</td>
                                                                <td>{c.soldtoname}</td>
                                                                <td>{c.sku}</td>
                                                                <td>{c.po_qty}</td>
                                                                <td
                                                                    style={{
                                                                        borderLeft:
                                                                            '1px solid #002b55',
                                                                        borderRight:
                                                                            '1px solid #002b55',
                                                                    }}
                                                                >
                                                                    {c.port_atadate !== null
                                                                        ? c.port_atadate.match(
                                                                              regexDate
                                                                          )
                                                                        : 'IN TRANSIT'}
                                                                </td>
                                                                <td>
                                                                    <a
                                                                        href={c.webviewurl}
                                                                        target="_blank"
                                                                    >
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            fill="none"
                                                                            viewBox="0 0 24 24"
                                                                            strokeWidth="1"
                                                                            stroke="currentColor"
                                                                            className="w-6 h-6"
                                                                        >
                                                                            <path
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                                d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                                                                            />
                                                                        </svg>
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div
                                                className={styles.btnGroup}
                                                style={{
                                                    justifyContent: 'center',
                                                    marginTop: '5px',
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        marginLeft: '10px',
                                                    }}
                                                >
                                                    <div className={styles.btn}>
                                                        <Link
                                                            to={
                                                                '/glp_v2/glp_mtr?cntr=' +
                                                                getDifferentContiners(containerInfo)
                                                            }
                                                        >
                                                            View in monitoring
                                                        </Link>
                                                        <div className={styles.btn2}>
                                                            <Link
                                                                to={
                                                                    '/glp_v2/glp_mtr?cntr=' +
                                                                    getDifferentContiners(
                                                                        containerInfo
                                                                    )
                                                                }
                                                                target="_blank"
                                                            >
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    fill="none"
                                                                    viewBox="0 0 24 24"
                                                                    strokeWidth="2.3"
                                                                    stroke="#002b55"
                                                                    className={styles.link_svg}
                                                                >
                                                                    <path
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                        d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                                                                    />
                                                                </svg>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        marginLeft: '10px',
                                                    }}
                                                >
                                                    <div className={styles.btn}>
                                                        <Link
                                                            to={
                                                                '/glp_v2/glp_sl?cntr=' +
                                                                getDifferentContiners(containerInfo)
                                                            }
                                                        >
                                                            View in sales
                                                        </Link>
                                                        <div className={styles.btn2}>
                                                            <Link
                                                                to={
                                                                    '/glp_v2/glp_sl?cntr=' +
                                                                    getDifferentContiners(
                                                                        containerInfo
                                                                    )
                                                                }
                                                                target="_blank"
                                                            >
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    fill="none"
                                                                    viewBox="0 0 24 24"
                                                                    strokeWidth="2.3"
                                                                    stroke="#002b55"
                                                                    className={styles.link_svg}
                                                                >
                                                                    <path
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                        d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                                                                    />
                                                                </svg>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        marginLeft: '10px',
                                                    }}
                                                >
                                                    <div className={styles.btn}>
                                                        <Link
                                                            to={
                                                                '/glp_v2/glp_log?cntr=' +
                                                                getDifferentContiners(containerInfo)
                                                            }
                                                        >
                                                            View in logistics
                                                        </Link>
                                                        <div className={styles.btn2}>
                                                            <Link
                                                                to={
                                                                    '/glp_v2/glp_log?cntr=' +
                                                                    getDifferentContiners(
                                                                        containerInfo
                                                                    )
                                                                }
                                                                target="_blank"
                                                            >
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    fill="none"
                                                                    viewBox="0 0 24 24"
                                                                    strokeWidth="2.3"
                                                                    stroke="#002b55"
                                                                    className={styles.link_svg}
                                                                >
                                                                    <path
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                        d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                                                                    />
                                                                </svg>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <h1
                                                style={{
                                                    textAlign: 'center',
                                                    fontWeight: '700',
                                                    paddingTop: '10px',
                                                }}
                                            >
                                                No container in this port
                                            </h1>
                                        </>
                                    )}
                                    {alertHistory.length > 0 && (
                                        <div className={styles.alertHistoryDiv}>
                                            <div
                                                className={styles.alertHistoryTitle}
                                                onClick={openAlertHistory}
                                            >
                                                See Alert History{' '}
                                                {!isOpen ? (
                                                    <ChevronRightIcon className="w-4 h-4" />
                                                ) : (
                                                    <ChevronDownIcon className="w-4 h-4" />
                                                )}
                                            </div>
                                            {isOpen && (
                                                <AlertHistoryBox alertHistory={alertHistory} />
                                            )}
                                        </div>
                                    )}
                                </div>
                            </InfoWindow>
                        )}
                    </Marker>
                );
            });
            const alertMarkerMap = alerts?.map((alert: any, index: any) => (
                <Marker
                    key={'markAlertInMap' + index}
                    position={{ lat: parseFloat(alert.latitude), lng: parseFloat(alert.longitude) }}
                    onClick={() => onClickMarker(index, alert.longitude, alert.latitude)}
                    icon={{
                        url: '/images/circle-blue.png',
                        scaledSize: new google.maps.Size(15, 13),
                    }}
                    onMouseOver={() => onMouseHover(index)}
                    onMouseOut={() => setShowQuickInf('')}
                >
                    {spinner === index && showTooltip !== alert.id && (
                        <InfoWindow>
                            <div style={{ overflow: 'hidden' }}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="#002b55"
                                    className={`w-6 h-6 ${styles.spin}`}
                                >
                                    <path d="M4.755 10.059a7.5 7.5 0 0 1 12.548-3.364l1.903 1.903h-3.183a.75.75 0 1 0 0 1.5h4.992a.75.75 0 0 0 .75-.75V4.356a.75.75 0 0 0-1.5 0v3.18l-1.9-1.9A9 9 0 0 0 3.306 9.67a.75.75 0 1 0 1.45.388Zm15.408 3.352a.75.75 0 0 0-.919.53 7.5 7.5 0 0 1-12.548 3.364l-1.902-1.903h3.183a.75.75 0 0 0 0-1.5H2.984a.75.75 0 0 0-.75.75v4.992a.75.75 0 0 0 1.5 0v-3.18l1.9 1.9a9 9 0 0 0 15.059-4.035.75.75 0 0 0-.53-.918Z" />
                                </svg>
                            </div>
                        </InfoWindow>
                    )}
                    {showQuickInf === index && showTooltip === '' && !spinner && (
                        <InfoWindow options={{ disableAutoPan: true, maxWidth: 300 }}>
                            <div style={{ alignItems: 'center' }}>
                                <h5>Alert title:</h5>
                                <h5
                                    style={{
                                        fontWeight: '500',
                                        color: '#002b55',
                                        fontSize: '15px',
                                    }}
                                >
                                    {alert.alert_title}
                                </h5>
                                <h5>Click for more inf</h5>
                            </div>
                        </InfoWindow>
                    )}
                    {showTooltip === index && (
                        <InfoWindow onCloseClick={onCloseTooltip}>
                            <div className={styles.portsContainer}>
                                <div className={styles.portName}>
                                    <h5>General alert</h5>
                                </div>
                                {alert.alert_description && (
                                    <>
                                        <div className={styles.portsContainer}>
                                            <div className={styles.portStatus}>
                                                <h5>Alert title</h5>
                                            </div>
                                            <div className={styles.portDescriptionStatus}>
                                                <h5>{alert.alert_title}</h5>
                                            </div>
                                            <div className={styles.portStatus}>
                                                <h5>Alert description</h5>
                                            </div>
                                            <div className={styles.portDescription}>
                                                <h5>{alert.alert_description}</h5>
                                            </div>
                                        </div>
                                    </>
                                )}
                                <div style={{ display: 'flex', gap: '20px' }}>
                                    <div>
                                        <div className={styles.portStatus}>
                                            <h5>{'Latitude'}</h5>
                                        </div>
                                        <div className={styles.portDescription}>
                                            <h5>{alert.latitude}</h5>
                                        </div>
                                    </div>
                                    <div>
                                        <div className={styles.portStatus}>
                                            <h5>{'Longitude'}</h5>
                                        </div>
                                        <div className={styles.portDescription}>
                                            <h5>{alert.longitude}</h5>
                                        </div>
                                    </div>
                                    <div>
                                        <div className={styles.portStatus}>
                                            <h5>{'From Date'}</h5>
                                        </div>
                                        <div className={styles.portDescription}>
                                            <h5>{alert.alert_from_date}</h5>
                                        </div>
                                    </div>
                                    <div>
                                        <div className={styles.portStatus}>
                                            <h5>{'To Date'}</h5>
                                        </div>
                                        <div className={styles.portDescription}>
                                            <h5>{alert.alert_to_date}</h5>
                                        </div>
                                    </div>
                                </div>
                                {generalHistory.length > 0 && (
                                    <div className={styles.alertHistoryDiv}>
                                        <div
                                            className={styles.alertHistoryTitle}
                                            onClick={openGeneralHistory}
                                        >
                                            See Alert History{' '}
                                            {!isOpenGeneral ? (
                                                <ChevronRightIcon className="w-4 h-4" />
                                            ) : (
                                                <ChevronDownIcon className="w-4 h-4" />
                                            )}
                                        </div>
                                        {isOpenGeneral && (
                                            <GeneralHistoryBox generalHistory={generalHistory} />
                                        )}
                                    </div>
                                )}
                            </div>
                        </InfoWindow>
                    )}
                </Marker>
            ));
            const vesselMarkerMap = vessels?.map((vessel: any, index: number) => {
                const rotationAngle = parseFloat(vessel.course || 0);
                return (
                    <Marker
                        key={'markVesselInMap' + index}
                        position={{ lat: parseFloat(vessel.lat), lng: parseFloat(vessel.lon) }}
                        onClick={() => {
                            onClickMarker(vessel.ship_id);
                        }}
                        icon={{
                            url: `data:image/svg+xml;utf-8,
                            <svg xmlns="http://www.w3.org/2000/svg" fill="orange" viewBox="0 0 24 24" strokeWidth="1.5" stroke="gray" class="w-6 h-6" style="transform: rotate(${
                                rotationAngle - 90
                            }deg);">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5" />
                            </svg>`,
                            scaledSize: new google.maps.Size(
                                vessel.status === '' ? 14 : 20,
                                vessel.status === '' ? 14 : 20
                            ),
                        }}
                        onMouseOver={() => onMouseHover(vessel.ship_id)}
                        onMouseOut={() => setShowQuickInf('')}
                    >
                        {spinner === vessel.ship_id && showTooltip !== vessel.ship_id && (
                            <InfoWindow>
                                <div style={{ overflow: 'hidden' }}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        fill="#002b55"
                                        className={`w-6 h-6 ${styles.spin}`}
                                    >
                                        <path d="M4.755 10.059a7.5 7.5 0 0 1 12.548-3.364l1.903 1.903h-3.183a.75.75 0 1 0 0 1.5h4.992a.75.75 0 0 0 .75-.75V4.356a.75.75 0 0 0-1.5 0v3.18l-1.9-1.9A9 9 0 0 0 3.306 9.67a.75.75 0 1 0 1.45.388Zm15.408 3.352a.75.75 0 0 0-.919.53 7.5 7.5 0 0 1-12.548 3.364l-1.902-1.903h3.183a.75.75 0 0 0 0-1.5H2.984a.75.75 0 0 0-.75.75v4.992a.75.75 0 0 0 1.5 0v-3.18l1.9 1.9a9 9 0 0 0 15.059-4.035.75.75 0 0 0-.53-.918Z" />
                                    </svg>
                                </div>
                            </InfoWindow>
                        )}
                        {showQuickInf === vessel.ship_id && showTooltip === '' && !spinner && (
                            <InfoWindow options={{ disableAutoPan: true }}>
                                <div style={{ alignItems: 'center' }}>
                                    <h5>Vessel</h5>
                                    <h5
                                        style={{
                                            fontWeight: '500',
                                            color: '#002b55',
                                            fontSize: '15px',
                                        }}
                                    >
                                        {vessel.shipname}
                                    </h5>
                                    <h5>Click for more inf</h5>
                                </div>
                            </InfoWindow>
                        )}
                        {showTooltip === vessel.ship_id && (
                            <InfoWindow onCloseClick={onCloseTooltip} options={{ maxWidth: 1200 }}>
                                <div className={styles.portsContainer}>
                                    <div className={styles.header}>
                                        <div style={{ display: 'flex', alignItems: 'end' }}>
                                            <div>
                                                <h5 className={styles.portStatus}>Ship Name </h5>
                                                <h5
                                                    className={styles.portName}
                                                    style={{ fontSize: '25px', fontWeight: '600' }}
                                                >
                                                    {vessel.shipname}
                                                </h5>
                                            </div>
                                        </div>
                                        {containerInfo.length > 0 && (
                                            <div className={styles.btnGroup}>
                                                <div
                                                    style={{ display: 'flex', marginLeft: '10px' }}
                                                >
                                                    <div className={styles.btn}>
                                                        <Link
                                                            to={
                                                                '/glp_v2/glp_mtr?cntr=' +
                                                                getDifferentContiners(containerInfo)
                                                            }
                                                        >
                                                            View in monitoring
                                                        </Link>
                                                        <div className={styles.btn2}>
                                                            <Link
                                                                to={
                                                                    '/glp_v2/glp_mtr?cntr=' +
                                                                    getDifferentContiners(
                                                                        containerInfo
                                                                    )
                                                                }
                                                                target="_blank"
                                                            >
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    fill="none"
                                                                    viewBox="0 0 24 24"
                                                                    strokeWidth="2.3"
                                                                    stroke="#002b55"
                                                                    className={styles.link_svg}
                                                                >
                                                                    <path
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                        d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                                                                    />
                                                                </svg>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    style={{ display: 'flex', marginLeft: '10px' }}
                                                >
                                                    <div className={styles.btn}>
                                                        <Link
                                                            to={
                                                                '/glp_v2/glp_sl?cntr=' +
                                                                getDifferentContiners(containerInfo)
                                                            }
                                                        >
                                                            View in sales
                                                        </Link>
                                                        <div className={styles.btn2}>
                                                            <Link
                                                                to={
                                                                    '/glp_v2/glp_sl?cntr=' +
                                                                    getDifferentContiners(
                                                                        containerInfo
                                                                    )
                                                                }
                                                                target="_blank"
                                                            >
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    fill="none"
                                                                    viewBox="0 0 24 24"
                                                                    strokeWidth="2.3"
                                                                    stroke="#002b55"
                                                                    className={styles.link_svg}
                                                                >
                                                                    <path
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                        d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                                                                    />
                                                                </svg>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    style={{ display: 'flex', marginLeft: '10px' }}
                                                >
                                                    <div className={styles.btn}>
                                                        <Link
                                                            to={
                                                                '/glp_v2/glp_log?cntr=' +
                                                                getDifferentContiners(containerInfo)
                                                            }
                                                        >
                                                            View in logistics
                                                        </Link>
                                                        <div className={styles.btn2}>
                                                            <Link
                                                                to={
                                                                    '/glp_v2/glp_log?cntr=' +
                                                                    getDifferentContiners(
                                                                        containerInfo
                                                                    )
                                                                }
                                                                target="_blank"
                                                            >
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    fill="none"
                                                                    viewBox="0 0 24 24"
                                                                    strokeWidth="2.3"
                                                                    stroke="#002b55"
                                                                    className={styles.link_svg}
                                                                >
                                                                    <path
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                        d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                                                                    />
                                                                </svg>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    {containerInfo.length > 0 ? (
                                        <div className={styles.table_wrapper}>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>CONTAINER</th>
                                                        <th>CLIENT</th>
                                                        <th>SKU</th>
                                                        <th>QTY</th>
                                                        <th>DISCHARGE PORT</th>
                                                        <th>FINAL PORT</th>
                                                        <th
                                                            style={{
                                                                borderLeft: '1px solid #002b55',
                                                            }}
                                                        >
                                                            ATA
                                                        </th>
                                                        <th>GLP ETA</th>
                                                        <th>MT ETA</th>
                                                        <th
                                                            style={{
                                                                borderRight: '1px solid #002b55',
                                                            }}
                                                        >
                                                            LEAD TIME
                                                        </th>
                                                        <th>TRACK</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {containerInfo.map((c: any, i: number) => (
                                                        <tr key={i}>
                                                            <td>{c.cntr_truckno}</td>
                                                            <td>{c.soldtoname}</td>
                                                            <td>{c.sku}</td>
                                                            <td>{c.po_qty}</td>
                                                            <td>
                                                                {c.discharging__port.toUpperCase()}
                                                            </td>
                                                            <td>{c.finalportname}</td>
                                                            <td
                                                                style={{
                                                                    borderLeft: '1px solid #002b55',
                                                                }}
                                                            >
                                                                {c.port_atadate === null
                                                                    ? 'NOT ARRIVED'
                                                                    : c.port_atadate}
                                                            </td>
                                                            <td>{c.port_etadate}</td>
                                                            <td>{c.plannedeta.match(regexDate)}</td>
                                                            <td
                                                                style={{
                                                                    borderRight:
                                                                        '1px solid #002b55',
                                                                }}
                                                            >
                                                                {c.leadtime} days
                                                            </td>
                                                            <td>
                                                                <a
                                                                    href={c.webviewurl}
                                                                    target="_blank"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        fill="none"
                                                                        viewBox="0 0 24 24"
                                                                        strokeWidth="1"
                                                                        stroke="currentColor"
                                                                        className="w-6 h-6"
                                                                    >
                                                                        <path
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                            d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                                                                        />
                                                                    </svg>
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    ) : (
                                        <h1
                                            style={{
                                                textAlign: 'center',
                                                fontWeight: '700',
                                            }}
                                        >
                                            No container in this vessel
                                        </h1>
                                    )}
                                    <div className={styles.last_refresh}>
                                        Last Update: {containerInfo[0]?.dt}
                                    </div>
                                </div>
                            </InfoWindow>
                        )}
                    </Marker>
                );
            });
            const pinMarkerMap = lead_time?.map((lead_time: any, index: any) => (
                <Marker
                    key={'markVesselInMap' + index}
                    position={{
                        lat: parseFloat(lead_time.latitude),
                        lng: parseFloat(lead_time.longitude),
                    }}
                    onClick={() => onClickMarker(index)}
                    icon={{
                        url: '/images/pin.png',
                        scaledSize: new google.maps.Size(25, 25),
                    }}
                >
                    {showTooltip === index && (
                        <InfoWindow onCloseClick={onCloseTooltip} options={{ maxWidth: 900 }}>
                            <div className={styles.portsContainer}>
                                <div className={styles.header}>
                                    <div style={{ display: 'flex', alignItems: 'end' }}>
                                        <div>
                                            <h5 className={styles.portStatus}>Discharging Port</h5>
                                            <h5 className={styles.portDescriptionStatus}>
                                                {lead_time.discharging__port}
                                            </h5>
                                            <h5 className={styles.portStatus}>Latitude</h5>
                                            <h5 className={styles.portDescriptionStatus}>
                                                {lead_time.latitude}
                                            </h5>
                                            <h5 className={styles.portStatus}>Longitude</h5>
                                            <h5 className={styles.portDescriptionStatus}>
                                                {lead_time.longitude}
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </InfoWindow>
                    )}
                </Marker>
            ));

            setPortMarker(portMarkerMap);
            setAlertMarker(alertMarkerMap);
            setVesselMarker(vesselMarkerMap);
            setPinMarker(pinMarkerMap);
            setIsLoadedMarket(true);
        }
    }, [
        ports,
        alerts,
        vessels,
        showTooltip,
        showQuickInf,
        isLoaded,
        lead_time,
        spinner,
        isOpen,
        isOpenGeneral,
    ]);

    const memoized = useMemo(() => {
        return (
            <Map
                className={className}
                title={title}
                data_chart={data_chart}
                center_map={center_map}
                zoom={zoom}
                setIsLoadedMarkets={setIsLoaded}
                isLoaddedMarkets={isLoadedMarkets}
                touch={touch}
            >
                {portMarker}
                {alertMarker}
                {vesselMarker}
                {pinMarker}
            </Map>
        );
    }, [center_map, zoom, portMarker, alertMarker, vesselMarker, pinMarker]);

    return <>{memoized}</>;
};
